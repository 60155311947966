import { Vue, Component, Prop } from 'vue-property-decorator'
import { VNodeData } from 'vue'
import Img from '~/types/api/Img'

interface Attrs {
  src: string
  srcset: string
  itemprop?: string,
  title?: string
  alt?: string
}

@Component
export default class ImageElement extends Vue {
  @Prop({ type: Object, required: true })
  readonly img!: Img

  @Prop({ type: String, default: '' })
  readonly className!: string

  @Prop({ type: Boolean, default: false })
  readonly isMicrodata!: boolean

  @Prop({ type: String, default: '' })
  readonly title?: string

  @Prop({ type: String, default: '' })
  readonly alt!: string

  get nodeData (): VNodeData {
    const attrs: Attrs = {
      src: this.src,
      srcset: this.srcset
    }

    if (this.title) {
      attrs.title = this.title
    }

    if (this.alt) {
      attrs.alt = this.alt
    }

    if (this.isMicrodata) {
      attrs.itemprop = 'image'
    }

    return {
      class: this.className,
      attrs
    }
  }

  get src (): string {
    return this.img.m
  }

  get srcset (): string {
    let srcset = ''
    srcset += `${this.img.d} 1200w,`
    srcset += `${this.img.m} 768w,`
    srcset += `${this.img.t} 480w`

    return srcset
  }

  render () {
    return (
      <img {...this.nodeData} />
    )
  }
}
